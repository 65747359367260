<template>
  <div>
    <b-card title="در دست توسعه میباشد 🚀">
      <!--      <b-card-text>All the best for your new project.</b-card-text>-->
      <!--      <b-card-text>Please make sure to read our <b-link-->
      <!--        href="https://pixinvent.com/demo/vuexy-vuejs-admin-dashboard-template/documentation/"-->
      <!--        target="_blank"-->
      <!--      >-->
      <!--        Template Documentation-->
      <!--      </b-link> to understand where to go from here and how to use our template.</b-card-text>-->
      <!--    </b-card>-->

      <!--    <b-card title="Want to integrate JWT? 🔒">-->
      <!--      <b-card-text>We carefully crafted JWT flow so you can implement JWT with ease and with minimum efforts.</b-card-text>-->
      <!--      <b-card-text>Please read our  JWT Documentation to get more out of JWT authentication.</b-card-text>-->
<!--      {{ state.userInfo }}-->
    </b-card>
  </div>
</template>

<script>
import { BCard } from 'bootstrap-vue'

export default {
  components: {
    BCard,
  },
}
</script>

<style>

</style>
